.categories {
	background-color: var(--color-four);
	text-align: center;
	color: var(--color-two);

	&__edge {
		fill: var(--color-four);
	}

	&__container {
		padding-top: 1px;
		padding-bottom: 1px;
	}

	&__section-heading {
		@include font-preset--3;
		margin: 2ex 0 0;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__section-copy {
		@include font-preset--6;
		margin: 0.4ex auto 0;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;
		max-width: 750px;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__columns {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		justify-content: space-between;
		margin: 3.5rem 0 1rem;
	}

	&__cta {
		margin-bottom: 3rem;
	}

	&__card {
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;
		margin: 0 0 2.5rem;
		background-color: var(--color-fifteen);
		width: calc(50% - 1.25rem);
		background-position: 50%;
		background-size: cover;

		&--in-viewport {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__link {
		text-decoration: none;
		color: var(--color-two);
		padding: 1.25rem;
		display: block;
		transition: transform 0.3s;

		&:hover,
		&:focus {
			transform: scale(1.02) rotate(1.25deg);
		}
	}

	&__inner {
		padding: 1rem;
		aspect-ratio: 1.3;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		background-size: cover;
		background-position: 50%;
	}

	&__heading {
		@include font-preset--6;
		margin-top: 0;
		color: var(--color-four);
		transition: color 0.3s;
		background-color: var(--color-fifteen);
		display: inline-block;
		padding: 0.5rem 1rem;
	}
}


