.text-columns {
	margin-top: calc(var(--vertical-spacing) + 4vw) !important;
	background-color: var(--color-three);

	+.text-columns {
		margin-top: 0!important;
	}

	&--alt {
		background-color: var(--color-twelve);

		.text-columns__edge {
			display: none;

			&--two {
				display: block;
			}
		}

		.text-columns__cols {
			> * {
				color: var(--color-one);
			}
		}

		.text-columns__heading {
			color: var(--color-one);
		}

		.text-columns__container {
			padding-bottom: 9rem;
		}
	}

	&__edge {
		path {
			fill: var(--color-three);
		}

		&--two {
			display: none;

			path {
				fill: var(--color-twelve);
			}
		}
	}

	&__container {
		padding-top: 3rem;
		padding-bottom: 6rem;
	}

	&__cols {
		margin-top: 1rem;
		column-count: 1;
		column-gap: 1.8rem;

		> * {
			line-height: 1.7;
			font-size: 0.87rem;
			color: var(--color-two);

			&:first-child {
				margin: 0;
			}
		}

		@media (min-width: 380px) {
			column-count: 2;
		}

		@media (min-width: 500px) {
			column-count: 3;
		}

		@media (min-width: 700px) {
			column-count: 4;
		}

		@media (min-width: 950px) {
			column-count: 5;
		}

		@media (min-width: 1200px) {
			column-count: 6;
		}
	}

	&__heading {
		@include font-preset--4;
		color: var(--color-twelve);
		margin: 0;
	}
}