.image {
	transform: translate(0, 2rem);
	opacity: 0;
	transition: transform 0.4s, opacity 0.35s;

	&--wide {
		.image__wrapper {
			img {
				max-width: unset;
			}
		}
	}

	&--slider {
		.image__caption {
			text-align: center;
			max-width: 700px;
			margin: auto;
		}
	}

	&--small {
		img {
			max-width: 200px;
		}
	}

	&--in-viewport {
		transform: translate(0, 0);
		opacity: 1;
	}

	&__video-embed {
		aspect-ratio: 1.77;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__caption {
		font-size: 0.87rem;
		max-width: unset;
	}

	&__wrapper {
		img {
			max-width: 640px;
			margin: auto;
		}
	}

	.slick-arrow {
		width: 2rem;
		height: 2rem;
		appearance: none;
		cursor: pointer;
		border: 0;
		background-color: transparent;
		background-image: url('/assets/img/icon-arrow.svg');
		background-size: 1.5rem;
		background-position: 50%;
		background-repeat: no-repeat;
		font-size: 0;
		padding: 0;
		z-index: 10;
		position: absolute;
		left: -2rem;
		top: 50%;
		transform: translateY(-50%)rotate(180deg);

		&.slick-next {
			left: unset;
			right: -2rem;
			transform: translateY(-50%);
		}
	}
}

