:root {
	// COLOURS
	--color-one: #323232;
	--color-two: #fff;
	--color-three: #197167;
	--color-four: #19A870;
	--color-five: #33568A;
	--color-six: #6A90C8;
	--color-seven: #873C3B;
	--color-eight: #E75055;
	--color-nine: #F8B037;
	--color-ten: #15958D;
	--color-eleven: #A9A9A9;
	--color-twelve: #F9BB5D;
	--color-thirteen: #414141;
	--color-fourteen: #BBDACE;
	--color-fifteen: #F7F5EA;

	
	// TYPOGRAPHY
	--font-one: 'Open Sans', sans-serif;
	--font-two: 'Trunk', sans-serif;

	
	// VERTICAL SPACING
	
	--vertical-spacing: 4rem;
	@media (min-width: 900px) {
		--vertical-spacing: 6.8rem;
	}

	// CONTAINER SPACING
	--container-spacing: 2rem;
	@media (min-width: 900px) {
		--container-spacing: 5rem;
	}
}
