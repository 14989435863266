$newsletter-b-1: 1000px;

.newsletter {
	padding: 1.5rem 0 3rem;
	background-color: var(--color-nine);
	color: var(--color-one);

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__text {
		width: 100%;

		@media (min-width: $newsletter-b-1) {
			width: calc(50% - 1.25rem);
		}
	}

	&__heading {
		@include font-preset--2;
		margin: 0;
		color: var(--color-three);
	}

	&__copy {
		margin-top: 0.1ex;
	}

	&__form {
		width: 100%;
		margin-top: 2rem;

		@media (min-width: $newsletter-b-1) {
			width: calc(50% - 1.25rem);
			margin-top: 0.8rem;
		}

		label {
			display: block;
			font-weight: 600;
		}

		input {
			display: block;
			width: 100%;
			max-width: 26rem;
			padding: 0.8ex;
			border: 0;
			margin-top: 1.25ex;
		}
	}

	&__button {
		margin-top: 1ex;

		&:hover {
			background-color: var(--color-four);
		}
	}
}
