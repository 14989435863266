.call-out {
	background-color: var(--color-nine);
	color: var(--color-two);

	+ .call-out {
		margin-top: 0;
	}

	&--brown {
		background-color: var(--color-seven);

		.call-out__quote {
			&::before {
				color: var(--color-twelve);
			}
		}

		.call-out__edge {
			fill: var(--color-seven);
		}
	}

	&--in-viewport {
		.call-out__container,
		.call-out__image {
			transform: translate(0, 0);
			opacity: 1;
		}
	}

	&__edge {
		fill: var(--color-nine);
	}

	&__container {
		padding-top: var(--container-spacing);
		padding-bottom: var(--container-spacing);
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s, opacity 0.35s;
	}

	&__image {
		position: absolute;
		bottom: calc(100% - 1rem);
		right: var(--container-spacing);
		width: 20%;
		max-width: 9rem;
		transform: translate(0, 2rem);
		opacity: 0;
		transition: transform 0.4s 0.2s, opacity 0.35s 0.2s;
	}

	&__copy {
		@include font-preset--5;
		margin: 0;

		a {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	&__quote {
		@include font-preset--5;
		margin: 0;

		&::before {
			content: "“";
			position: absolute;
			top: -0.19ex;
			right: calc(100% + 0.07ex);
			font-size: 7rem;
			color: var(--color-two);
		}
	}

	&__source {
		@include font-preset--8;
	}
}
