$intro-b-1: 720px;

.intro {
	transform: translate(0, 2rem);
	opacity: 0;
	transition: transform 0.4s, opacity 0.35s;

	&--columns {
		.intro__container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
		}
	}

	&--bg {
		color: var(--color-two);

		+ .intro--bg {
			margin-top: 0 !important;
		}

		.intro__container {
			padding-top: 5rem;
			padding-bottom: 6rem;
		}

		.intro__heading {
			color: var(--color-two);
		}
	}

	&--bg-four {
		background-color: var(--color-four);

		.intro__edge path {
			fill: var(--color-four);
		}
	}

	&--bg-five {
		background-color: var(--color-five);

		.intro__edge path {
			fill: var(--color-five);
		}
	}

	&--bg-seven {
		background-color: var(--color-seven);

		.intro__edge path {
			fill: var(--color-seven);
		}
	}

	&--bg-eight {
		background-color: var(--color-eight);

		.intro__edge path {
			fill: var(--color-eight);
		}
	}

	&--bg-nine {
		background-color: var(--color-nine);

		.intro__edge path {
			fill: var(--color-nine);
		}
	}

	&--in-viewport {
		transform: translate(0, 0);
		opacity: 1;
	}

	
	&--with-image {
		.intro__container > *:not(.intro__image) {
			@media (min-width: $intro-b-1) {
				padding-right: calc(25% + 2rem);
			}
		}
	}

	+ .intro--with-image-no-heading {
		margin-top: 0 !important;
	}

	&--with-image-no-heading {
		.intro__image {
			@media (min-width: $intro-b-1) {
				top: 0.8rem;
			}
		}
	}

	&__column {
		width: calc(50% - 1.5rem);

		.button {
			margin-top: 1.25ex;
		}

		@media (max-width: $intro-b-1) {
			width: 100%;

			&:last-of-type {
				margin-top: 4rem;
			}
		}
	}

	&__large-image {
		margin-top: 2.5rem;
	}

	&__caption {
		font-size: 0.87rem;
		max-width: unset;
	}
		
	&__image {
		max-width: 200px;
		margin-top: 2rem;

		@media (min-width: $intro-b-1) {
			margin-top: 0;
			position: absolute;
			top: 4.5rem;
			right: var(--container-spacing);
			width: calc(25% - var(--container-spacing));
		}
	}
	
	
	+* {
		margin-top: calc(var(--vertical-spacing) / 2) !important;
	}

	&__container {}

	&__heading {
		@include font-preset--4;
		margin: 0;
		color: var(--color-ten);
	}

	&__copy {
		@include font-preset--9;
		margin-top: 1.4ex;
	}

	&__button {
		margin-top: 2ex;
	}
}
