$nav-break-one: 1000px;

.fixed {
	overflow: hidden;
}

.nav-toggle {
	appearance: none;
	width: 1.75rem;
	height: 1.75rem;
	background-color: transparent;
	background-image: url('/assets/img/icon-menu.png');
	background-size: contain;
	background-position: 50%;
	border: 0;
	margin-right: 1.25rem;

	&--active {
		background-image: url('/assets/img/icon-menu-close.png');
	}

	@media (min-width: $nav-break-one) {
		display: none;
	}
}

.nav {
	@media (max-width: $nav-break-one) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: var(--color-three);
		z-index: 10;
		display: flex;
		justify-content: center;
		opacity: 0;
		pointer-events: none;

		&--active {
			opacity: 1;
			pointer-events: all;
		}
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		width: calc(100% - 4rem);

		@media (min-width: $nav-break-one) {
			text-align: right;
			width: unset;
		}
	}

	&__item {
		display: block;
		margin: 1ex 0;
		border-top: 1px solid var(--color-ten);
		padding: 2ex 0 0.5ex;

		@media (min-width: $nav-break-one) {
			display: inline-block;
			margin: 0 2.75ex 0 0;
			border-top: 0;
			padding: 0;

			&:not(&:last-of-type) {
				&::after {
					content: "";
					position: absolute;
					right: -1.35ex;
					top: 50%;
					transform: translateY(-50%);
					width: 1px;
					height: 100%;
					background-color: var(--color-ten);
				}
			}
		}
	}

	&__link {
		@include font-preset--5;
		text-decoration: none;
		color: var(--color-fourteen);
		transition: color 0.2s;
		cursor: pointer;

		&--active {
			color: var(--color-two);
		}

		&:hover {
			color: var(--color-two);
		}

		@media (min-width: 1000px) and (max-width: 1300px) {
			font-size: 1.3rem;
		}

	}
}