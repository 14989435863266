.sub-nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;

	p {
		margin: 0 0.5ex 2.2ex;
		text-align: center;
	}

	&__item {
		@include font-preset--6;
		background-color: var(--color-fifteen);
		color: var(--color-four);
		text-decoration: none;
		margin: 0 0.5ex 1ex;
		padding: 0.2ex 0.8ex;
		cursor: pointer;
		transition: background-color 0.2s;

		&:hover {
			background-color: var(--color-two);
		}
	}
}