.header {
	background-color: var(--color-three);

	&__container  {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	&__logo  {
		max-width: 8rem;
		margin-right: 2rem;

		@media (min-width: 600px) {
			max-width: 12rem;
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__button {}
}




